import React from "react";
import { Link } from "react-router-dom";
const Partners = () => {
  return (
    <section className="service_details_area sec_pad">
      <div className="container">
        <h2
          className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
          data-wow-delay="0.2s"
        >
          Renewable Energy Alliance Declaration
        </h2>
        <div className="row">
          <div className="col-lg-5 pr_70">
            <img
              src={require("../../img/home4/partners.png")}
              alt=""
              width="500px"
            />
          </div>
          <div className="col-lg-7">
            <div className="details_content">
              <div className="sec_title">
                <p className="f_400 f_size_15">
                Adopt the ROBe2 protocol into our business by accepting ROB tokens and offering incentives to transact in ROB. 
                </p>
                <p className="f_400 f_size_15">
                Gather focus and resources to achieve the vision to deploy renewable energy in order to reduce greenhouse gasses in the atmosphere, allowing the stabilization of the climate system, and a sustainable and secure transition to a low-carbon economy.
                </p>
                <p className="f_400 f_size_15">
                Promote the positive impact of renewable energy technologies and how they can transform societies and create economic growth and new opportunities.
                </p>
                <p className="f_400 f_size_15">
                Educate and engage clients, customers and partners about the power of renewable energy to create decentralized access to energy in developing countries in order to free them from poverty. 
                </p>
                <p className="f_400 f_size_15">
                Agree that renewable energy, combined with enhanced energy efficiency, can increasingly cover the anticipated steep increase in global energy needs in the coming decades.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-lg-7">
            <div className="details_content">
              <div className="sec_title">
                <p className="f_400 f_size_15">
                Proactively utilize the REA networks to reduce the negative implications of fossil fuels and deforestation worldwide.
                </p>
                <p className="f_400 f_size_15">
                Educate and engage clients, customers and partners about the power of renewable energy to create decentralized access to energy, in developing countries in order to free them from poverty.
                </p>
                <p className="f_400 f_size_15">
                Commit to establishing an international movement for renewable energy that facilitates the cooperation between its Members. 
                </p>
                <p className="f_400 f_size_15">
                We adopt the best technologies from the most innovative companies and organizations that are solving the climate and carbon crisis.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 pr_70">
            <img
              src={require("../../img/home4/partners-2.png")}
              alt=""
              width="500px"
            />
          </div>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-lg-7">
            <img
              src={require("../../img/home4/partners-3.png")}
              alt=""
              width="500px"
            />
          </div>
          <div className="action_btn d-flex align-items-center mt_60">
                <Link to="/Apply-now" className="btn_hover agency_banner_btn">
                  Apply Now
                </Link>
              </div>
            
          {/* <div className="col-lg-5">
            <div className="job_info">
              <div className="info_head">
                <i className="ti-receipt"></i>
                <h6 className="f_p f_600 f_size_18 t_color3">
                  Become a Member.
                </h6>
              </div>

              <div className="info_item">
                <h6>Membership Advantages </h6>
              </div>
              <div className="info_item">
                <h6>Agree to uphold the REA Declaration</h6>
              </div>
              <div className="info_item">
                <h6>Display the Logo</h6>
              </div>
              <div className="info_item">
                <h6>Approve devices</h6>
              </div>
              <div className="info_item">
                <h6>Contribute to the vision</h6>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default Partners;
