import React ,{useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Reveal from "react-reveal/Reveal";
import ReactGA from 'react-ga'
const Opportunity = () => {
    useEffect(()=>{
        ReactGA.pageview('/Opportunity')
    })
    return (
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Opportunity" Pdescription="" />
            <section className="payment_features_area">
                <div className="bg_shape shape_one"></div>
                <div className="bg_shape shape_two"></div>
                <div className="bg_shape shape_three"></div>
                <div className="container">
                    <div className="row flex-row-reverse featured_item">
                        <div className="col-lg-7 d-flex align-items-center">
                            <Reveal effect="fadeInLeft" duration={1200}>
                                <div className="payment_features_content pr_70">
                                    <h2>Opportunity </h2>
                                    <ol>
                                        <li className="mb-2">
                                            A global movement of companies, organizations and
                                            individuals committed to providing solutions to the
                                            world’s energy and environmental problems is desperately
                                            needed.
                                        </li>
                                        <li className="mb-2">
                                            A transition to renewable energy production can help solve
                                            the persistent global problems facing humanity.
                                        </li>
                                        <li className="mb-2">
                                            Shifting to a green economy could yield a direct economic
                                            gain of $26 trillion through 2030 compared with business
                                            as usual. This could produce over 65 million new
                                            low-carbon jobs.
                                        </li>
                                        <li className="mb-2">
                                            A green transition, including a shift to renewable energy
                                            such as the manufacturing of electric vehicles and
                                            construction of energy-efficient buildings, will create 24
                                            million jobs by 2030, far more than the 6 million that
                                            could be lost.
                                        </li>
                                        <li className="mb-2">
                                            The combination of generating renewable energy and
                                            providing a financial system will help release many who
                                            are trapped in poverty.
                                        </li>
                                        <li className="mb-2">
                                            Create an energy star standard for renewable products.
                                        </li>
                                    </ol>
                                </div>
                            </Reveal>
                        </div>
                        <div className="col-lg-5">
                            <Reveal effect="fadeInRight" duration={800}>
                                <div>
                                    <img
                                        src={require("../img/home9/featured_img_two.png")}
                                        alt=""
                                        className="img img-fluid"
                                    />
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default Opportunity;