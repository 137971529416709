import React , {useEffect}from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Sustainability from '../components/Service/Sustainability';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ReactGA from 'react-ga';

const SustainabilityPage = () => {
    useEffect(()=>{
        ReactGA.pageview('/Sustainability')
    })

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Sustainability"/>
            <Sustainability/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default SustainabilityPage;