import React, { Component } from "react";

class ClimateChangeItem extends Component {
  render() {
    var { iShap, Sicon, text, description, nClass, link = "" } = this.props;
    return (
      <div
        className={`p_service_item agency_service_item pr_70 wow fadeInUp ${nClass}`}
      >
          <div className="icon">
                    <img src={require ("../img/home4/" + iShap)} alt=""/>
                    <i className={Sicon}></i>
                </div>
            <h5 className="f_600 f_p t_color3">{text}</h5>
            <p className="d-block">{description}</p>
            <p className="mb-0">
              <a target="_blank" href={link} rel="noopener noreferrer">
                Read More
              </a>
            </p>
      </div>
    );
  }
}
export default ClimateChangeItem;
