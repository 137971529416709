import React , {useEffect}from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ClimateChange from '../components/ClimateChange';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ReactGA from 'react-ga';
const ClimateChangePage = () => {
    useEffect(()=>{
        ReactGA.pageview('/Climate-Change')
    })
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Climate Change"/>
             <ClimateChange/> 
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default ClimateChangePage;