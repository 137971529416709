import React, { Component } from "react";
import Slider from "react-slick";
class AgencyAbout extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let ServiceData = this.props.ServiceData;
    return (
      <section className="agency_about_area d-flex bg_color mt-4">
        <div className="col-lg-6 about_content_left ">
          <div className="about_content mb_30">
            <h2 className="f_size_30 f_700 l_height45 mb_20">
              THE REA COMMUNITY.
            </h2>
            <ol>
              <li className="mb-2">
                We work with ambitious partners who want to define a sustainable
                future and not hide from it. Together, we define a bold ambition
                and achieve extraordinary results that redefine the green energy
                space.
              </li>
              <li className="mb-2">
                Invite the most brilliant minds in the space to come together
                through annual events and podcasts.
              </li>
              <li className="mb-2">
                We aim to build the most focused and aligned Renewable Energy
                Community the World Has Ever Seen through our commitment to
                upholding a zero-carbon world.
              </li>
              <li className="mb-2">
                Rewards and recognition to members who are leading the way with
                the greatest impact.
              </li>
            </ol>
          </div>
        </div>
        <div className="col-lg-6 about_img">
         
          <Slider className="about_img_slider" {...settings}>
            <div className="item">
              <div className="about_item w45">
                <img src={require("../../img/home4/team1.jpg")} alt="" />
                <div className="about_text">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext1}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team2.jpg")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext2}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team3.png")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext3}
                  </h5>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="about_item w45">
                <img src={require("../../img/home4/team4.jpg")} alt="" />
                <div className="about_text">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext1}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team5.jpg")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext2}
                  </h5>
                </div>
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team6.jpg")} alt="" />
                <div className="about_text text_two">
                  <span className="br"></span>
                  <h5 className="f_size_18 l_height28 mb-0">
                    {ServiceData.agtext3}
                  </h5>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default AgencyAbout;
