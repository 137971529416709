import React , {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Reveal from "react-reveal/Reveal";
import ReactGA from 'react-ga'
const Problems = () => {
    useEffect(()=>{
        ReactGA.pageview('/Challenges')
    })
    return (
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Challenges" Pdescription="" />
            <section className="payment_features_area">
                <div className="bg_shape shape_one"></div>
                <div className="bg_shape shape_two"></div>
                <div className="bg_shape shape_three"></div>
                <div className="container">
                    <div className="row featured_item mt-4">
                        <div className="col-lg-7 d-flex align-items-center">
                            <Reveal effect="fadeInRight" duration={800}>
                                <div className="payment_features_content pl_70">
                                    {/* <div className="icon">
                                      <img className="img_shape" src={require('../../img/home9/icon_shape.png')} alt=""/>
                                      <img className="icon_img" src={require('../../img/home9/icon2.png')} alt=""/>
                                  </div> */}
                                    <h2>Current Problem</h2>
                                    <ol>
                                        <li className="mb-2">
                                            <b>Climate change: </b>the concentration of carbon dioxide
                                            (CO2)​​​​​​​ in our atmosphere is 416 part​s per million
                                            which is the highest it has been in human history. This
                                            has helped to make 2020 the 2nd hottest year on record.
                                        </li>
                                        <li className="mb-2">
                                            <b>Technology: </b>mass adaptation of renewable energy
                                            technologies is lagging to meet the goal of net-zero
                                            global greenhouse gas emissions by 2050.
                                        </li>
                                        <li className="mb-2">
                                            <b>The planet: </b>the unsustainable use of land, soil,
                                            water and energy for food contributes to greenhouse
                                            emissions that cause rising temperatures. Higher
                                            temperatures, in turn, affect resources to produce food
                                            which creates more poverty. Up to 811 million people in
                                            the world faced hunger in 2020, 161 million more than in
                                            2019.
                                        </li>
                                        <li>
                                            <b>Poverty: </b>1.1 billion people don’t have access to
                                            reliable energy which denies them basic opportunities such
                                            as running a business and providing light for their
                                            children to study.
                                        </li>
                                    </ol>
                                </div>
                            </Reveal>
                        </div>
                        <div className="col-lg-5 justify-content-center d-flex">
                            <Reveal effect="fadeInLeft">
                                <div>
                                    <img
                                        src={require("../img/home9/featured_img.png")}
                                        alt=""
                                        className="img img-fluid"
                                    />
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default Problems;