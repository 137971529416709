import React from 'react';

const Certification =()=> {
    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                    <img
                  src={require("../../img/home4/Certifications.png")}
                  alt=""
                  width="500px"
                /> </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">



                                <p className="f_400 f_size_15">To set a global standard by establishing specifications and certifications  for companies who are creating renewable energy.</p>
                                <p className="f_400 f_size_15">All practices, organizations and products must regenerate the economy and create positive impacts.</p>
                                <p className="f_400 f_size_15">Partnerships with providers and JV’s to have a specialization in renewable energy as part of degree programs and diplomas.</p>
                                <p className="f_400 f_size_15">Create levels of certification depending on the depth of renewable integration.</p>
                                <p className="f_400 f_size_15">Events, awards, and accolades for companies and organizations that are making an impact in the renewable energy space.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Certification;