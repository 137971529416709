import React,{useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import PaymentBanner from '../components/Banner/PaymentBanner';
import PaymentTestimonial from '../components/Testimonial/PaymentTestimonial';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';
import ReactGA from 'react-ga';

//Home Page
const Paymentprocessing = () => {

useEffect(()=>{
    ReactGA.pageview('/Home')
})
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" isHome={true}/>
            <PaymentBanner/>
            {/* <AppFeatures/> */}
            
            {/* <EventAbout/> */}

            <PaymentTestimonial/>
            <FooterThree FooterData={FooterData}/>
        </div>
    )
}
export default Paymentprocessing;