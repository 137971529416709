import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal/';
class Breadcrumb extends Component {
    render() {
        var { Ptitle, Pdescription, breadcrumbClass, imgName, aboutUs = false } = this.props;
        return (
            <section className={`${breadcrumbClass}`}>
                <img className="breadcrumb_shap" src={require("../img/" + imgName)} alt="" />
                <div className="container">
                    <div className="breadcrumb_content text-center">
                        <Reveal effect="fadeInUp"><h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">{Ptitle}</h1></Reveal>

                        {aboutUs ? (
                            <div>
                                <p className="f_400 w_color f_size_16 l_height26">1. To educate and advocate renewable energy use by encouraging and
                                    empowering companies and individuals to transition to clean energy.

                                </p><p className="f_400 w_color f_size_16 l_height26">
                                    2. To set a global standard by establishing specifications and certifications
                                    for the creation of green energy.

                                </p>
                                <p className="f_400 w_color f_size_16 l_height26">
                                    3. Author incentivization programs that reward companies and individuals
                                    for creating renewable energy and implementing those solutions.

                                </p>
                                <p className="f_400 w_color f_size_16 l_height26">
                                    4. Advocate sustainable development - adopt the best technologies from the
                                    most innovative companies and organizations that are solving the
                                    problems

                                </p>
                                <p className="f_400 w_color f_size_16 l_height26">5. Change the relationship with the planet to be symbiotic and regenerative. </p>
                                <p className="f_400 w_color f_size_16 l_height26">
                                    6. Building a community and inviting the most brilliant minds in the space to
                                    come together through annual events and podcasts.</p>
                            </div>
                        ) : (
                            <p className="f_400 w_color f_size_16 l_height26">{Pdescription}</p>
                        )}
                    </div>
                </div>
            </section>
        )
    }
}
export default Breadcrumb;