import React from "react";

const Sustainability = () => {
  return (
    <section className="service_details_area sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 pr_70">
            <img
              src={require("../../img/home4/sustainability.png")}
              alt=""
              width="500px"
            />
          </div>
          <div className="col-lg-7">
            <div className="details_content">
              <div className="sec_title">
                <p className="f_400 f_size_15">
                  
                  We adopt the best technologies from the most innovative
                  companies and organizations that are solving the climate and
                  carbon crisis.
                </p>
                <p className="f_400 f_size_15">
                  
                  Our goal is to provide renewable power to those in poverty in
                  order to help them help themselves.
                </p>
                <p className="f_400 f_size_15">
                To create an energy platform that can be maintained and sustained without causing further harm to the environment.
                </p>
                <p className="f_400 f_size_15">
                  
                Institute best practices that refocus existing projects and help design them to be environmentally friendly.
                </p>
                <p className="f_400 f_size_15">Advocate being 100% carbon negative and 100% of the planet’s energy coming from renewable sources.</p>
                <p className="f_400 f_size_15">
                  
                  Be a significant part of eradicating extreme poverty by 2050.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Sustainability;
