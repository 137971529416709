import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const TeamModal = ({ show, handleCloseParent, memberName }) => {
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => {
    setShowModal(false);
    handleCloseParent();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      fullscreen={true}
      className="team-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-white">
          {memberName === "magdy"
            ? "Dr. Magdi EL Messiry"
            : memberName === "tom"
            ? "Dr. Tom Davis"
            : memberName === "james"
            ? "Dr. James Canton"
            : "Kay Hiramine LLD"}
          <h6>
            {" "}
            {memberName === "magdy"
              ? "X- head of Textile Engineering Dept., Faculty of Engineering, Alexandria University"
              : memberName === "tom"
              ? "Chief Renewable Officer"
              : memberName === "james"
              ? "Chief Positivity Officer"
              : "Global Head of Client Relations"}
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-3 col-sm-12 text-center">
            <img
              src={
                memberName === "magdy"
                  ? require("../img/drmagdy.jpg")
                  : memberName === "tom"
                  ? require("../img/drtom.jpg")
                  : memberName === "james"
                  ? require("../img/drjames.png")
                  : require("../img/Kay.png")
              }
              alt="member"
              className="img img-fluid img-thumbnail"
            />
          </div>
          <div className="col-lg-9 col-sm-12 align-self-center">
            {memberName === "tom" ? (
              <div>
                <p className="f_p f_size_20 mb-2 text-white">
                  Tom Davis is a visionary entrepreneur, CEO, and Leadership
                  expert to Fortune 500 companies and non-profit organizations
                  around the globe. He has worked in 40 countries and trained
                  leaders from 80 nations. He is the Chief Renewable Officer of
                  the Renewable Energy Alliance whose mission is to
                  strategically bring people and organizations together to
                  create a 100% renewable energy future to regenerate the world.
                </p>
                <p className="f_p f_size_20 mb-2 text-white">
                  Tom has worked with major companies across the globe including
                  Ingersoll-Rand, Lear, Celsa Group, Marvell Technology, Ficosa,
                  Grifols, Vueling Airlines, Intel, Chick-Fil-A, Coca-Cola,
                  Pfizer, Bristol-Myers Squibb, and GlaxoSmithKline. He is
                  focused on creating a renewable economic platform that can be
                  accessed by anyone in the world.
                </p>
                <p className="f_p f_size_20 mb-2 text-white">
                  He is also the author of 5 books and his articles have
                  appeared in over 30 national and international publications.
                </p>
              </div>
            ) : memberName === "magdy" ? (
              <div>
                <p className="f_p f_size_20 mb-2 text-white">
                  Dr. MAGDI EL MESSIRY was born in the Mediterranean town
                  Alexandria – Egypt in 1942. He had his primary and secondary
                  education in Alexandria schools as well as got his engineering
                  education at the Faculty of Engineering, Alexandria University
                  which is one of the famous universities in the Mediterranean
                  countries. In 1964 he finished his BSc. degree in textile
                  engineering with the excellent first grade of honor.
                </p>
                <p className="f_p f_size_20 mb-2 text-white">
                  In 1967 he had his MSc degree from the same institute.
                  Egyptian government sent him to one of the famous textile
                  institutes in Russia (Moscow Textile University) for obtaining
                  his Ph.D. degree in the Design of Textile Machinery. On his
                  return to Alexandria University in 1971, he was assigned as a
                  staff member and he got his professorship in 1981. He held the
                  position of Vice-Dean in the period from 1994 to 1998. He
                  worked as Head of Textile Department (2000-2002 and
                  2005-2011).
                </p>
                <p className="f_p f_size_20 mb-2 text-white">
                  He is considered one of the main experts in the Textile
                  Industry in Egypt. He was a member of the Directing Board of
                  Spinning and Weaving Holding Company, the main Textile Company
                  in Egypt. He is now a technical adviser to the Owner Board.
                  From 1998 till now he is a member of the technical committee
                  of the Egyptian Textile Consolidation Fund. He supervised more
                  than 40 theses in masters and doctorate degrees. His list of
                  publications exceeds 100 papers in the different fields of
                  textile science.
                </p>
                <p className="f_p f_size_20 mb-2 text-white">
                  Dr. Magdi participated in establishing several textile
                  departments in Egypt and in Arab countries. He carried out
                  several granted projects on an international level with the
                  colleagues from UK, France, Spain, the USA, CZ, Algeria,
                  Tunisia, and Morocco. He is participating in the scientific
                  board in several journals. In the last 5 years, he acted as an
                  international expert in innovation and technology transfer. In
                  1999, Dr. El Messiry Also Received the ALEXANDRIA UNIVERSITY
                  AWARD for SCIENTIFIC ACHEVIMENT and in 2008 AWARDED the
                  ALEXANDRIA UNIVERSITY ACHIEVEMENTS. The scientific research
                  interests in the last decade are composite, nanotechnology,
                  and protective fabric.
                </p>
              </div>
            ) : memberName === "james" ? (
              <div>
                <p className="f_p f_size_20 mb-2 text-white">
                  Leading global futurist, serial entrepreneur, CEO Institute
                  for Global Futures. Chief Global Finance, Green Capital Ltd.
                  Advisor World Bank, Corporate Eco-Forum, XpansivCBL, Club of
                  the Future. author Future Smart. Former Apple executive, MIT
                  Media Lab, CNN commentator.
                </p>
              </div>
            ) : (
              <div>
                <p className="f_p f_size_20 mb-2 text-white">
                  Mr. Hiramine has advised and operated in over 80 countries
                  over his 25 year career. He served as CEO of PSC Inc. which
                  advised Fortune 500 companies including Walmart, Home Depot,
                  FedEx, Delta Airlines and Citibank. He has also served as
                  Director of Humanitarian International Services Group which
                  managed $50mm of sustainable development and micro-businesses
                  through Africa and Asia. He is a partner in Trivium Strategic
                  Capital, a private investment & advisory firm.
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TeamModal;
