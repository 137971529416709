import React from "react";

const PaymentTestimonial = () => {
  return (
    <React.Fragment>
      <section className="payment_testimonial_area">
        <div className="container">
          <div className="row payment_testimonial_info flex-row-reverse">
            <div className="col-lg-7 d-flex align-items-center">
              <div className="testimonial_content">
                <div className="icon">,,</div>
                <p className="f_p f_size_20">
                  “We believe in a completely renewable and sustainable energy
                  economy that empowers everyone to create their own renewable
                  energy. Our goal is to utilize modern technology to
                  incentivize an alliance of companies and organizations to come
                  together and create renewable energies and set the standard
                  for how those companies will connect and operate. We want to
                  change the relationship between people and the planet to be
                  symbiotic and regenerative. ”
                </p>
                <div className="author f_600 f_p t_color f_size_20">
                  Dr. Tom Davis
                </div>
                <div className="author_description f_p f_size_15">
                  Chief Renewable Officer
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-self-center">
              <div className="testimonial_img">
                <img
                  src={require("../../img/drtom.jpg")}
                  alt=""
                  width="500px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default PaymentTestimonial;
