import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import AgencyAbout from "../components/About/AgencyAbout";
import FooterTwo from "../components/Footer/FooterTwo";
import ServiceData from "../components/Service/ServiceData";
import FooterData from "../components/Footer/FooterData";
import TeamModal from "../components/TeamModal";
import ReactGA from "react-ga";

const About = () => {
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [memberName, setMemberName] = useState(false);

  useEffect(() => {
    ReactGA.pageview("/About-us");
  });

  const handleOpenModal = (member) => {
    setShowMemberModal(true);
    setMemberName(member);
  };
  return (
    <div className="body_wrapper about-us-page">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="About Us"
        Pdescription="The REA is a global ecosystem that advocates and rewards the global use of renewable energy."
      />
      <AgencyAbout ServiceData={ServiceData} />
      {/* <Partner pClass="partner_logo_area_five bg_color"/> */}

      {/* //dr tom bio */}
      <div className="row payment_testimonial_info  mt-5 pt-5 mx-5">
        <div className="col-lg-3 d-flex align-self-center about_content_left">
          <div className="testimonial_img">
            <img
              src={require("../img/drtom.jpg")}
              alt=""
              className="img img-fluid"
            />
          </div>
        </div>
        <div className="col-lg-9 d-flex align-items-center px-5">
          <div className="testimonial_content pl-0 mt-0">
            <div className="testimonial_content pl-0 mt-4">
              <div className="author_description f_p f_size_15">
                Chief Renewable Officer
              </div>
              <div className="author f_600 f_p t_color f_size_20 mb-2">
                Dr. Tom Davis
              </div>
            </div>
            <p className="f_p f_size_20 mb-2">
              Tom Davis is a visionary entrepreneur, CEO, and Leadership expert
              to Fortune 500 companies and non-profit organizations around the
              globe. He has worked in 40 countries and trained leaders from 80
              nations. He is the Chief Renewable Officer of the Renewable Energy
              Alliance whose mission is to strategically bring people and
              organizations together to create a 100% renewable energy future to
              regenerate the world.
            </p>
            <button
              className="btn_hover agency_banner_btn"
              onClick={() => handleOpenModal("tom")}
            >
              Read More
            </button>
          </div>
        </div>
        {/* <div className="row payment_testimonial_info px-3">
          <div className="col d-flex align-items-center px-5  about_content_left">
            <div className="testimonial_content pl-0 mt-4">
              <p className="f_p f_size_20">
                Tom has worked with major companies across the globe including
                Ingersoll-Rand, Lear, Celsa Group, Marvell Technology, Ficosa,
                Grifols, Vueling Airlines, Intel, Chick-Fil-A, Coca-Cola,
                Pfizer, Bristol-Myers Squibb, and GlaxoSmithKline. He is focused
                on creating a renewable economic platform that can be accessed
                by anyone in the world.
              </p>
              <p className="f_p f_size_20">
                He is also the author of 5 books and his articles have appeared
                in over 30 national and international publications.
              </p>
        
            </div>
          </div>
        </div>*/}
      </div>
      {/* //dr magdy bio */}
      <div className="row payment_testimonial_info mt-5 pt-5 mx-5">
        <div className="col-lg-3 d-flex align-self-center x-5 about_content_right">
          <div className="testimonial_img">
            <img
              src={require("../img/drmagdy.jpg")}
              alt=""
              className="img img-fluid"
            />
          </div>
        </div>

        <div className="col-lg-9 d-flex align-items-center px-5 about_content_left">
          <div className="testimonial_content pl-0 pl-lg-0 mt-0">
            <div className="testimonial_content pl-0 mt-4">
              <div className="author_description f_p f_size_15">
                X- head of Textile Engineering Dept., Faculty of Engineering,
                Alexandria University
              </div>
              <div className="author f_600 f_p t_color f_size_20 mb-2">
                Dr. MAGDI EL MESSIRY
              </div>
            </div>
            <p className="f_p f_size_20 mb-2">
              Dr. MAGDI EL MESSIRY was born in the Mediterranean town Alexandria
              – Egypt in 1942. He had his primary and secondary education in
              Alexandria schools as well as got his engineering education at the
              Faculty of Engineering, Alexandria University which is one of the
              famous universities in the Mediterranean countries. In 1964 he
              finished his BSc. degree in textile engineering with the excellent
              first grade of honor.
            </p>
            <button
              className="btn_hover agency_banner_btn"
              onClick={() => handleOpenModal("magdy")}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
      {/* <div className="row payment_testimonial_info ">
        <div className="col d-flex align-items-center px-5  about_content_left">
          <div className="testimonial_content pl-0 mt-4">
          <p className="f_p f_size_20">
              In 1967 he had his MSc degree from the same institute. Egyptian
              government sent him to one of the famous textile institutes in
              Russia (Moscow Textile University) for obtaining his PhD degree in
              Design of Textile Machinery. On his return to Alexandria
              University in 1971, he was assigned as staff member and he got his
              professorship in 1981. He held position of the Vice Dean in period
              from 1994 to 1998. He worked as Head of Textile Department
              (2000-2002 and 2005-2011).
            </p>
            <p className="f_p f_size_20">
              
              He is considered as one of the main experts in the Textile
              Industry in Egypt. He was a member of the Directing Board of
              Spinning and Weaving Holding Company, the main Textile Company in
              Egypt. He is now a technical adviser to the Owner Board. Since
              1998 till now he is a member of technical committee of the
              Egyptian Textile Consolidation Fund. He supervised more than 40
              theses in master and doctor degrees. His list of publication
              exceeds 100 papers in the different fields of textile science.
            </p>
            <p className="f_p f_size_20">
              
              Dr. Magdi participated in establishing several textile departments
              in Egypt and in Arab countries. He carried out several granted
              projects on international level with the colleagues from UK,
              France, Spain, USA, CZ, Algeria, Tunisia, and Morocco. He is
              participating in scientific board in several journals. In the last
              5 years he acted as an international expert in innovation and
              technology transfer. In 1999, Dr. El Messiry Also Received the
              ALEXANDRIA UNIVERSITY AWARD for SCIENTIFIC ACHEVIMENT and in 2008
              AWARDED the ALEXANDRIA UNIVERSITY ACHIEVEMENTS. The scientific
              research interests in the last decade are composite,
              nanotechnology and protective fabric.
            </p>
         
          </div>
        </div>
      </div>
       */}
      {/* //Dr. James Canton bio */}
      <div className="row payment_testimonial_info  mt-5 pt-5 mx-5">
        <div className="col-lg-3 d-flex align-self-center about_content_left">
          <div className="testimonial_img">
            <img
              src={require("../img/drjames.png")}
              alt=""
              className="img img-fluid"
            />
          </div>
        </div>

        <div className="col-lg-9 d-flex align-items-center px-5">
          <div className="testimonial_content pl-0 mt-0">
            <div className="testimonial_content pl-0 mt-4">
              <div className="author_description f_p f_size_15">
                Chief Positivity Officer
              </div>
              <div className="author f_600 f_p t_color f_size_20 mb-2">
                Dr. James Canton
              </div>
            </div>
            <p className="f_p f_size_20 mb-2">
              Leading global futurist, serial entrepreneur, CEO Institute for
              Global Futures. Chief Global Finance, Green Capital Ltd. Advisor
              World Bank, Corporate Eco-Forum, XpansivCBL, Club of the Future.
              author Future Smart. Former Apple executive, MIT Media Lab, CNN
              commentator.
            </p>
            <button
              className="btn_hover agency_banner_btn"
              onClick={() => handleOpenModal("james")}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
      {/* //dr kay bio */}
      <div className="row payment_testimonial_info  mt-5 pt-5  mx-5">
        <div className="col-lg-3 d-flex align-self-center  about_content_right">
          <div className="testimonial_img">
            <img
              src={require("../img/Kay.png")}
              alt=""
              className="img img-fluid"
            />
          </div>
        </div>
        <div className="col-lg-9 d-flex align-items-center px-5">
          <div className="testimonial_content pl-0 pl-lg-0  mt-0">
            <div className="author_description f_p f_size_15">
              Global Head of Client Relations
            </div>
            <div className="author f_600 f_p t_color f_size_20 mb-2">
              Kay Hiramine LLD
            </div>
            <p className="f_p f_size_20 mb-2">
              Mr. Hiramine has advised and operated in over 80 countries over
              his 25 year career. He served as CEO of PSC Inc. which advised
              Fortune 500 companies including Walmart, Home Depot, FedEx, Delta
              Airlines and Citibank. He has also served as Director of
              Humanitarian International Services Group which managed $50mm of
              sustainable development and micro-businesses through Africa and
              Asia. He is a partner in Trivium Strategic Capital, a private
              investment & advisory firm.
            </p>
            <button
              className="btn_hover agency_banner_btn"
              onClick={() => handleOpenModal("kay")}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
      {showMemberModal && (
        <TeamModal
          show={showMemberModal}
          handleCloseParent={() => {
            setShowMemberModal(false);
          }}
          memberName={memberName}
        />
      )}
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
