import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var { mClass, nClass, cClass, slogo, isHome = false } = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                    <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                        <div className={`container ${cClass}`}>
                            <Link className={`navbar-brand ${slogo}`} to="/">
                                <img src={require("../img/logo2.png")} alt="" />
                                <img src={require("../img/logo.png")} alt="logo" />
                            </Link>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`navbar-nav menu  ${nClass}`}>
                                    {!isHome && (
                                        <li className="nav-item" >
                                            <Link to="/" className="nav-link" >
                                                Home
                                            </Link>
                                        </li>
                                    )}
                                     <li className="nav-item">
                                        <Link to="/About" className="nav-link"  >
                                            About Us
                                        </Link>
                                    </li>
                                        <li className="dropdown submenu nav-item">
                                    <Link to=""  className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Global Impact</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><Link exact  className="nav-link" to='/Sustainability'>Sustainability</Link></li>
                                         <li className="nav-item"><Link exact  className="nav-link" to='/Climate-Change'>Climate Change</Link></li>
                                      {/*  <li className="nav-item"><Link exact  className="nav-link" to='/Fight-Povert'>Fight Poverty</Link></li> */}
                                  
                                    </ul>
                                </li> 
                                    <li className="nav-item">
                                        <Link to="/WhatWeDo" className="nav-link"  >
                                            What we do
                                        </Link>
                                    </li>
                                    <li className="dropdown submenu nav-item">
                                    <Link to=""  className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Global Landscape</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><Link exact  className="nav-link" to='/Challenges'>Challenges</Link></li>
                                        <li className="nav-item"><Link exact  className="nav-link" to='/Opportunity'>Opportunity</Link></li>
                                  
                                    </ul>
                                </li> 
                                    <li className="dropdown submenu nav-item">
                                        <Link to="/Certification" className="nav-link"  >
                                            Certification
                                        </Link>

                                    </li>                                                                        
                                    <li className="dropdown submenu nav-item">
                                        <Link to="/Partners" className="nav-link"  >
                                            Partners
                                        </Link>
                                    </li>
                                                                       
                                    {/* <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li> */}
                                    <li className="nav-item">
                                        <Link to="/Apply-now" className="nav-link "  >
                                            Apply now
                                        </Link></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;