import React from 'react';

const EventAbout =()=>{
    return(
        <section className="event_about_area">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="event_about_img">
                            <img className="wow fadeInRight" data-wow-delay="0.2s" src={require("../../img/portfolio-details/pr_details2.jpg")} alt=""/>
                            <div className="about_bg"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="event_about_content">
                            <h2 className="wow fadeInUp">Mission</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.2s">To promote a completely renewable and 
                                sustainable energy economy by empowering 
                                everyone to create their own renewable 
                                energy. 
                                To utilize modern technology to incentivize an 
                                alliance of companies to come together and 
                                create renewable energies and set the 
                                standard for how those companies will 
                                connect and operate.
                                To change the relationship with people and 
                                the planet to be symbiotic and regenerative.</p>
                            {/* <div className="row">
                                <div className="col-6">
                                    <div className="event_about_item wow fadeInUp" data-wow-delay="0.2s">
                                        <img src={require("../../img/home-event/marker.png")} alt=""/>
                                        <h6>Where</h6>
                                        <p>Golly gosh the wireless posh loo smashing.</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="event_about_item wow fadeInUp" data-wow-delay="0.4s">
                                        <img src={require("../../img/home-event/timer.png")} alt=""/>
                                        <h6>When</h6>
                                        <p>Sunday to Wednesday April 24 to 04, 2019</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventAbout;