import React, { Component } from "react";
import ClimateChangeItem from "./ClimateChangeItem";
import Reveal from "react-reveal/Reveal";
class ClimateChangeItems extends Component {
  render() {
    return (
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            Renewable Energy Alliance
          </h2>
          <div className="row mb_30">
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1100}>
                <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="Renewable energy statistics, wind and water, solar "
                  link="https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Renewable_energy_statistics#Share_of_renewable_energy_more_than_doubled_between_2004_and_2019"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1400}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Renewable energy directive "
                  link="https://ec.europa.eu/energy/topics/renewable-energy/directive-targets-and-rules/renewable-energy-directive_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape2.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Renewable energy targets"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/directive-targets-and-rules/renewable-energy-targets_en"
                />
              </Reveal>
            </div>
           <hr/>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Renewable energy cooperation mechanism"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/directive-targets-and-rules/cooperation-mechanisms_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="EU National renewable energy action plans as of 2020 "
                  link="https://ec.europa.eu/energy/topics/renewable-energy/directive-targets-and-rules/national-renewable-energy-action-plans-2020_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape2.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Renewable energy Support schemes"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/support-schemes_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="EU strategy on offshore renewable energy"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/eu-strategy-offshore-renewable-energy_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="EU renewable energy financing mechanism"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/eu-renewable-energy-financing-mechanism_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape2.png"

                  Sicon="ti-bookmark-alt"
                  description="EU onshore and offshore wind power scheme"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/onshore-and-offshore-wind_en"
                />
              </Reveal>
            </div>


            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Ocean and hydropower scheme"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/ocean-and-hydropower_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Biomass scheme"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/biomass_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape2.png"

                  Sicon="ti-bookmark-alt"
                  description="EU Biofuel scheme"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/biofuels_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="EU solar power scheme"
                  link="https://ec.europa.eu/energy/topics/renewable-energy/solar-power_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="EU progress report on renewable energy 2001-2020"
                  link="https://wayback.archive-it.org/12090/20210802082910/https:/ec.europa.eu/energy/topics/renewable-energy/progress-reports_en"
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape2.png"

                  Sicon="ti-bookmark-alt"
                  description="Statistic about the rankings of countries in installed renewable energy capacity worldwide"
                  link="https://www.statista.com/statistics/267233/renewable-energy-capacity-worldwide-by-country/"
                />
              </Reveal>
            </div>    <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape3.png"

                  Sicon="ti-bookmark-alt"
                  description="Comparison between EU and Latin America renewable energy progress"
                  link="https://www.enelgreenpower.com/learning-hub/debates/energy-transition-europe-latin-america"
                />
              </Reveal>
            </div>    <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
              <ClimateChangeItem   iShap="icon_shape1.png"

                  Sicon="ti-bookmark-alt"
                  description="Pandemic effects on renewable energy in the EU (mostly spared)"
                  link="https://ec.europa.eu/info/news/renewable-energy-largely-spared-pandemic-effects-2021-jun-29_en"
                />
              </Reveal>
            </div>

          </div>
        </div>

     </section>
    );
  }
}
export default ClimateChangeItems;
