import React, {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Sservice from '../components/Service/Sservice/Sservice';
import ReactGA from 'react-ga';
const WhatWeDo = () => {
    useEffect(()=>{

        ReactGA.pageview('/What-we-do')
    })
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="What we do" Pdescription=""/>
            <Sservice/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default WhatWeDo;