import React, { useState } from "react";
import validator from "validator";

const GoGreenForm = () => {
  const IntialInputs = () => ({
    inputs: {
      firstName: "",
      lastName: "",
      companyName: "",
      industry: "",
      website: "",
      phone: "",
      email: "",
      description: "",
    },
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };

  const [state, setState] = useState(IntialInputs());
  const [sendClicked, setSendClicked] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setShowErrors(true);
    if (
      state.inputs.firstName !== "" &&
      state.inputs.lastName !== "" &&
      state.inputs.companyName !== "" &&
      state.inputs.website !== "" &&
      state.inputs.phone !== "" &&
      state.inputs.email !== ""
    ) {
      setSendClicked(true);
    }
  };

  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container">
        <div className="sign_info">
          <div className="row">
            <div className="col-lg-12">
              <div className="login_info">
                <form action="/#" className="login-form sign-in-form">
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control"
                        name="firstName"
                        onChange={handleChange}
                        value={state.inputs.firstName}
                      />
                      {showErrors === true &&
                        validator.isEmpty(state.inputs.firstName) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control"
                        name="lastName"
                        onChange={handleChange}
                        value={state.inputs.lastName}
                      />
                      {showErrors === true &&
                        validator.isEmpty(state.inputs.lastName) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        placeholder="Company Name"
                        className="form-control"
                        name="companyName"
                        onChange={handleChange}
                        value={state.inputs.companyName}
                      />
                         {showErrors === true &&
                        validator.isEmpty(state.inputs.companyName) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                    <div className="col-lg-6 form-group">
                      <select
                        className="form-control"
                        name="industry"
                        onChange={handleChange}
                        value={state.inputs.industry}
                      >
                        <option value="0">Utility</option>
                        <option value="1">Battery</option>
                        <option value="2">Transportation</option>
                        <option value="3">Non-Profit</option>
                        <option value="4">Government</option>
                        <option value="5">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        placeholder="Website"
                        className="form-control"
                        name="website"
                        onChange={handleChange}
                        value={state.inputs.website}
                      />
                      {showErrors === true &&
                        validator.isEmpty(state.inputs.website) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        placeholder="Phone number"
                        className="form-control"
                        name="phone"
                        onChange={handleChange}
                        value={state.inputs.phone}
                      />
                      {showErrors === true &&
                        validator.isEmpty(state.inputs.phone) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                    <div className="col-lg-12 form-group">
                      <input
                        type="email"
                        placeholder="E-mail"
                        className="form-control"
                        name="email"
                        onChange={handleChange}
                        value={state.inputs.email}
                      />
                      {showErrors === true &&
                        validator.isEmpty(state.inputs.email) && (
                          <div className="error-message">Required</div>
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <textarea
                        placeholder="Description"
                        className="form-control"
                        name="description"
                        onChange={handleChange}
                        value={state.inputs.description}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      className="btn_three"
                      onClick={(e) => handleSendEmail(e)}
                      disabled={sendClicked}
                    >
                         {sendClicked ? (
                      <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                        ) : (
                      "Submit"
                      )
                      }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default GoGreenForm;
